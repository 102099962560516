import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import RedditLogo from "../images/reddit-logo.png";
import DiscordLogo from "../images/discord.png";
import TwitterLogo from "../images/twitter.png";
import { useUtilityStore } from "../zustand/utilityStores";
import classNames from "classnames";
import BounceInComponent from "./animations/BounceInComponent";
import AccountCircle from "../images/account_circle.svg";

export const DISCORD_LINK = "https://discord.gg/uZfjga6SUq";

export default function NavBar() {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const setHeaderHeight = useUtilityStore((state) => state.setHeaderHeight);
  const [isFromAds, setIsFromAds] = useState(false);
  const cacheBuster = useRef(Math.random());
  const uid = useUtilityStore((state) => state.uid);
  const location = useLocation();
  const selectedLink = location.pathname;

  const isPro = useUtilityStore((state) => state.isPro);
  const sexyAISessionID = localStorage.getItem("sexySessionID");

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setHeight(ref.current.clientHeight);
      // @ts-ignore
      setHeaderHeight(ref.current.clientHeight);
    }

    // Check if utm_campaign is set, if so, store it in local storage
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get("utm_campaign");
    if (utmCampaign) {
      localStorage.setItem("utm_campaign", utmCampaign);
    }

    // Then check local storage and set isFromAds to true if it exists
    const storedUtmCampaign = localStorage.getItem("utm_campaign");
    if (storedUtmCampaign) {
      setIsFromAds(true);
    }
  }, []);

  const signInText = useUtilityStore((state) => state.isSignedIn)
    ? "Profile"
    : "Sign in";

  return (
    <div
      className="flex flex-wrap text-white text-lg p-2 select-none max-w-full overflow-x-auto mb-5"
      ref={ref}
    >
      <div className="font-bold flex justify-between w-full flex-wrap">
        <div className="flex flex-row w-full sm:w-auto">
          <div className="pl-2 mr-1">
            <Link to={"/"}>pornpen.ai</Link>
          </div>
          {isPro && (
            <BounceInComponent>
              <Link
                to="/subscriber"
                className="text-yellow-400 hover:opacity-70"
              >
                {" "}
                Pro
              </Link>
            </BounceInComponent>
          )}
          <div className="flex items-center">
            <Link to="/profile">
              <img src={AccountCircle} className="w-4 h-4 ml-2" alt="Account" />
            </Link>
          </div>
        </div>
        <div className="flex w-full sm:w-auto">
          {!isPro && (
            <div className="mr-4 text-yellow-400 underline">
              <a href="/getpro">Get Pro</a>
            </div>
          )}

          {isPro && sexyAISessionID && (
            <div className="mr-4 underline" style={{color: "#ec489a"}}>
              <a href={"https://sexy.ai/?sid=" + sexyAISessionID} target="_blank" rel="noopener noreferrer">
                Go to Sexy.AI
              </a>
            </div>
          )}

          <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
            <img
              src={DiscordLogo}
              className="w-6 h-6 mr-4 hover:scale-110"
              alt="Discord"
            />
          </a>
          <a
            href="https://reddit.com/r/pornpen"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={RedditLogo}
              className="w-6 h-6 mr-4 hover:scale-110"
              alt="Reddit"
            />
          </a>
          <a
            href="https://twitter.com/pornpen_ai"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={TwitterLogo}
              className="w-6 h-6 mr-4 hover:scale-110"
              alt="Reddit"
            />
          </a>
        </div>
      </div>
      <div className="flex w-full flex-wrap">
        <NavLink name="Make" to="/make" selected={selectedLink === "/make"} />
        <NavLink name="Feed" to="/feed" selected={selectedLink === "/feed"} />
        <NavLink
          name="Search"
          to="/search"
          selected={selectedLink === "/search"}
        />
        <NavLink
          name="Tags"
          to="/tags/browse"
          selected={selectedLink === "/tags/browse"}
        />
        <NavLink
          name="Games"
          to="/games"
          selected={selectedLink === "/games"}
        />
        <NavLink
          name={signInText}
          to="/profile"
          selected={selectedLink === "/profile"}
        />
        {!isPro && (
          <NavLink
            name="Pricing"
            to="/getpro"
            selected={selectedLink === "/getpro"}
          />
        )}
        <NavLink
          name="About"
          to="/about"
          selected={selectedLink === "/about"}
        />
        <a
          className="px-2 whitespace-nowrap hover:bg-slate-800 hover:rounded-lg"
          href="https://docs.google.com/forms/d/e/1FAIpQLSd7hDsRfFgmZ0OsQs3vuJubN2e_IufyITlZYeOmD9MtheW_lw/viewform"
        >
          Affiliate
        </a>
      </div>
    </div>
  );
}

function NavLink(props: { name: string; to: string; selected: boolean }) {
  return (
    <div
      className={classNames(
        "mr-1 px-2 whitespace-nowrap hover:bg-slate-800 hover:rounded-lg",
        {
          "bg-slate-700 rounded-lg px-2 no-underline": props.selected,
        }
      )}
    >
      <Link to={props.to}>{props.name}</Link>
    </div>
  );
}
